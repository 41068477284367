import {
  Appointment,
  AppointmentEvent,
  CalendarEventTypeEnum,
  ICreateCalendarEventQueryParams,
  IUpdateCalendarEventQueryParams,
  Service,
  Slot,
} from 'types';

import { IOption, Nullable } from '@linetweet/linetweet-ui';
import dayjs from 'dayjs';
import { GetAppointmentCustomer, GetAppointmentServices } from 'features/commons';
import { IntlShape } from 'react-intl';
import { isArrayEqual, TimeHelper } from '../../utils';
import { AppointmentFormSlotItem, CalendarEventCreateParams, ICalendarEventFormValues, ServiceTabsSelectOption } from './types';

export function uniqueSlots(arr: string[]) {
  const check = {};
  const res: string[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (!check[item]) {
      res.push(item);
      check[item] = true;
    }
  }
  return res;
}

export function createSlotOptions(params: {
  timeZone: string;
  slots: Slot[];
  employeeId?: Nullable<string>;
  defaultSlot?: AppointmentFormSlotItem;
}): IOption<number>[] {
  const currentDate = TimeHelper.toDayjs(Date.now(), params.timeZone);
  const currentDateString = currentDate.format('YYYY-MM-DD');
  const currentTimeMinutes = TimeHelper.getMinutesFromHHmmTimeString(currentDate.format('HH:mm'));

  const slotSet = new Set<string>();
  const slotOptions: IOption<number>[] = [];

  for (let slotIndex = 0; slotIndex < params.slots.length; slotIndex += 1) {
    const slot = params.slots[slotIndex];
    const employeeIdFits = !params.employeeId || slot.employeeId === params.employeeId;

    if (employeeIdFits) {
      for (let timeSlotIndex = 0; timeSlotIndex < slot.timeSlots.length; timeSlotIndex += 1) {
        const timeSlot = slot.timeSlots[timeSlotIndex];
        const value = TimeHelper.getMinutesFromHHmmTimeString(timeSlot);
        const slotFits = slot.date === currentDateString ? value >= currentTimeMinutes : true;

        if (slotFits && !slotSet.has(timeSlot)) {
          slotSet.add(timeSlot);
          slotOptions.push({
            label: timeSlot,
            value,
          });
        }
      }
    }
  }

  if (params.defaultSlot) {
    if (!slotOptions.find((option) => option.value === params.defaultSlot?.value)) {
      slotOptions.push(params.defaultSlot);
    }
  }

  return slotOptions.sort((optionA, optionB) => optionA.value - optionB.value);
}

export const getCreateCalendarEventFormDefaultValues = (
  params: CalendarEventCreateParams,
  timezone: string,
  intl: IntlShape,
): ICalendarEventFormValues => {
  let dateValue: string;
  if (params.date) {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(params.date, timezone));
    dateValue = params.date;
  } else {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));
  }
  let slotValue: Nullable<AppointmentFormSlotItem> = null;
  if (params.time) {
    slotValue = { label: TimeHelper.getHHmmTimeFromMinutes(params.time), value: params.time };
  }
  let servicesValue: ServiceTabsSelectOption[] = [];
  if (params.services) {
    servicesValue = params.services.map((service) => ({
      label: intl.formatMessage({ id: service.name }),
      value: service.id,
      name: service.name,
      tabId: service.category,
      extra: { ...service },
    }));
  }

  return {
    type: params.type || CalendarEventTypeEnum.APPOINTMENT,
    date: dateValue,
    note: params.note || null,
    slot: slotValue,
    gender: params.gender || null,
    services: servicesValue,
    employee: params.employeeId || null,
    duration: params.duration || null,
    title: params.title || null,
    lastName: params.lastName || '',
    firstName: params.firstName || '',
    email: params.email || '',
    phoneNumber: params.phoneNumber || '',
    recurring: params.recurring || null,
    from: params.from || null,
    to: params.to || null,
  };
};

export function getDateRange(date: string, timeZone?: string): string[] {
  const currentDay = TimeHelper.toDayjs(new Date(), timeZone);
  let startOfRange = TimeHelper.toDayjs(date, timeZone);

  if (startOfRange.isSame(currentDay, 'month')) {
    // if date in current month, we start from current day to not show previous months
    startOfRange = currentDay.clone();
  } else {
    // otherwise start with month start
    startOfRange = startOfRange.startOf('month');
  }
  const days: string[] = [];
  for (let i = 0; i < 30; i += 1) {
    days.push(TimeHelper.toStandardFormat(startOfRange.add(i, 'days')));
  }
  return days;
}

export const calendarEventFormValuesToCreateCalendarEventParams = (values: ICalendarEventFormValues): ICreateCalendarEventQueryParams => {
  const serviceValues = values.services.map((serviceOption) => serviceOption.value);

  let duration: Nullable<number> = null;
  let time = 0;
  if (values.type === CalendarEventTypeEnum.APPOINTMENT && values.slot?.value) {
    time = values.slot?.value;
  } else if (values.from != null && values.to != null) {
    time = values.from;
    duration = values.to - values.from;
  }

  if (values.type === CalendarEventTypeEnum.APPOINTMENT && !duration && values.services.length > 0) {
    duration = values.services.reduce((acc, service) => acc + service.extra.duration, 0);
  }

  let title: Nullable<string> = null;
  if (values.title != null) {
    title = values.title;
  } else {
    title = [values.firstName, values.lastName].join(' ').trim();
  }

  const params: ICreateCalendarEventQueryParams = {
    date: values.date,
    time,
    type: values.type,
    title,
    eventTypeData: {
      services: values.type === CalendarEventTypeEnum.APPOINTMENT ? serviceValues : undefined,
      suggestedEmployee: values.employee || undefined,
      customer: [CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(values.type)
        ? {
            name: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            gender: values.gender || undefined,
            locale: 'en',
          }
        : undefined,
    },
  };

  if (values.title != null) {
    params.title = values.title;
  }

  params.note = values.note || '';

  if (duration != null) {
    params.duration = duration;
  }

  return params;
};

export const calendarEventFormValuesToUpdateCalendarEventParams = (
  values: ICalendarEventFormValues,
  appointment: Appointment,
): IUpdateCalendarEventQueryParams => {
  const serviceValues = values.services.map((serviceOption) => serviceOption.value);

  let time = 0;
  if (values.slot?.value) {
    time = values.slot?.value;
  }

  let duration: Nullable<number> = null;
  if (values.type !== CalendarEventTypeEnum.APPOINTMENT && values.from != null && values.to != null) {
    time = values.from;
    duration = values.to - values.from;
  }

  if (!duration && values.services.length > 0) {
    duration = values.services.reduce((acc, service) => acc + service.extra.duration, 0);
    if (duration !== appointment.duration && values.type === CalendarEventTypeEnum.APPOINTMENT) {
      const services = values.services.map((service) => service.value);
      if (
        isArrayEqual(services, (appointment.eventTypeData as AppointmentEvent).services) &&
        appointment.time === time &&
        appointment.date === values.date
      ) {
        // if services are equal and start time is equal as well, we keep appointment initial duration
        duration = appointment.duration;
      }
    }
  }

  let title: Nullable<string> = null;
  if (values.title != null) {
    title = values.title;
  } else {
    title = [values.firstName, values.lastName].join(' ').trim();
  }

  const params: IUpdateCalendarEventQueryParams = {
    date: values.date,
    time,
    type: values.type,
    title,
    eventTypeData: {
      services: serviceValues,
      suggestedEmployee: typeof values.employee !== 'undefined' ? values.employee : undefined,
      customer: [CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(values.type)
        ? {
            name: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            gender: typeof values.gender !== 'undefined' ? values.gender : undefined,
            locale: 'en',
          }
        : undefined,
    },
  };

  params.note = values.note || '';

  if (duration != null) {
    params.duration = duration;
  }

  return params;
};

export const calendarEventToCalendarCreateValues = (
  calendarEvent: Appointment,
  allServices: Service[],
  employeeId: string | undefined,
  timezone: string,
): CalendarEventCreateParams => {
  const customer = GetAppointmentCustomer(calendarEvent);
  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);
  return {
    type: calendarEvent.type as unknown as CalendarEventTypeEnum,
    date: TimeHelper.getDateStringTz(new Date(), timezone),
    note: calendarEvent.note || null,
    gender: customer?.gender || null,
    services,
    duration: calendarEvent.duration || null,
    employeeId: employeeId || null,
    title: calendarEvent.title || null,
    lastName: customer?.lastName || '',
    firstName: customer?.name || '',
    email: customer?.email || '',
    phoneNumber: customer?.phoneNumber || '',
    recurring: null,
    from: calendarEvent.time,
    to: calendarEvent.time + (calendarEvent.duration || 0),
  };
};

export const calendarEventToCalendarEventFormValues = (
  calendarEvent: Appointment,
  allServices: Service[],
  employeeId: string | undefined,
  intl: IntlShape,
): ICalendarEventFormValues => {
  const customer = GetAppointmentCustomer(calendarEvent);
  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);

  return {
    type: calendarEvent.type as unknown as CalendarEventTypeEnum,
    date: calendarEvent.date,
    note: calendarEvent.note || null,
    slot: {
      label: TimeHelper.getTimeByMinutes(calendarEvent.time),
      value: calendarEvent.time,
    },
    gender: customer?.gender || null,
    services: services.map(
      (service): ServiceTabsSelectOption => ({
        label: intl.formatMessage({ id: service.name }),
        value: service.id,
        name: service.name,
        tabId: service.category,
        extra: { ...service },
      }),
    ),
    employee: employeeId || null,
    duration: calendarEvent.duration,
    title: calendarEvent.title || null,
    lastName: customer?.lastName || '',
    firstName: customer?.name || '',
    email: customer?.email || '',
    phoneNumber: customer?.phoneNumber || '',
    recurring: null,
    from: calendarEvent.time,
    to: calendarEvent.time + (calendarEvent.duration || 0),
  };
};
