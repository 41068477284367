import { Service } from '../types';

export const mapServiceIdsToServices = (allServices: Service[], serviceIds: string[]): Service[] => {
  if (!allServices) {
    return [];
  }
  if (!Array.isArray(serviceIds)) {
    return [];
  }
  return serviceIds
    .map((serviceId: string) => allServices.find((service) => service.id === serviceId))
    .filter((service) => !!service) as Service[];
};
