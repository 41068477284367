import React, { useMemo } from 'react';
import clsx from 'clsx';
import { alpha, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { CustomEventProps, WalkinComponentProps } from '../types';
import { PinnedIcon } from '../../../assets/icons';
import { WalkinsIcon } from '../../../assets/DesignAssets/BTL/Icons';

import styles from './DayEvent.module.scss';

export function Walkin30(
  {
    title,
    timeRange,
    borderColor,
    backgroundColor,
    serviceBackgroundColor,
  }: WalkinComponentProps,
) {
  return (
    <div
      style={{
        borderColor,
        backgroundColor,
      }}
      className={clsx(styles.dayEventRoot, styles.dayEventWalkin)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <div
          className={clsx(styles.dayEventServicesItem)}
          style={{ backgroundColor: serviceBackgroundColor }}
        >
          <WalkinsIcon />
        </div>

        <Stack direction="column" overflow="hidden" spacing={1} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <p
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextFullWidth,
              )}
            >
              {title}
            </p>
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>
            {timeRange}
          </span>
        </Stack>
      </Stack>
    </div>
  );
}

export function Walkin15(
  {
    timeRange,
    borderColor,
    backgroundColor,
    serviceBackgroundColor,
  }: WalkinComponentProps,
) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootDense, styles.dayEventWalkin)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <div
          className={clsx(styles.dayEventServicesItem, styles.dayEventServicesItemDense)}
          style={{ backgroundColor: serviceBackgroundColor }}
        >
          <WalkinsIcon />
        </div>

        <Stack direction="row" spacing={1.5} flex={1} alignItems="center" className={clsx([styles.dayEventBody, styles.dayEventBodyDense])}>
          <span
            className={clsx(
              styles.dayEventBodyText,
              styles.dayEventBodyTextSecondary,
              styles.dayEventBodyTextFullWidth,
              styles.dayEventTimeRange,
            )}
          >
            {timeRange}
          </span>
        </Stack>
      </Stack>
    </div>
  );
}

export function DayEventWalkin({ event }: CustomEventProps) {
  const intl = useIntl();

  const DayEventComponent = useMemo(() => {
    const duration = event.duration || 10;

    if (duration >= 30) {
      return Walkin30;
    }

    return Walkin15;
  }, [event.duration]);

  const timeRange = useMemo(() => `${event.startFormatted} - ${event.endFormatted}`, [event]);

  const colorProps = useMemo(() => ({
    serviceBackgroundColor: alpha('#5FA8D3', 0.35),
  }), [event]);

  return (
    <DayEventComponent
      title={intl.formatMessage({ id: 'calendar.walkinTitle' })}
      timeRange={timeRange}
      event={event}
      {...colorProps}
    />
  );
}
