import { Dayjs } from 'dayjs';

import { DayOverviewType, DaySummary, OverviewItem, Service, ServiceUsageOverview } from '../../../types';
import { mapServiceIdsToServices, sortByServicesCompareFn } from '../../../utils';

function isServiceUsageOverviewItemMatchServiceIds(serviceUsageOverview: ServiceUsageOverview, selectedServiceIds: string[]): boolean {
  for (let i = 0; i < selectedServiceIds.length; i++) {
    const serviceId = selectedServiceIds[i];

    if (serviceUsageOverview.services.includes(serviceId)) {
      return true;
    }
  }

  return false;
}

export function convertDaySummaryToCalendarDataType(
  daySummary: DaySummary,
  allServices: Service[],
  selectedServiceIds?: string[],
): DayOverviewType {
  const res: DayOverviewType = {
    overview: [],
    employees: { value: 0 },
    date: daySummary.date,
    weekEnd: !!daySummary.isWeekend,
  };

  const overview: OverviewItem[] = [];

  if (daySummary.services) {
    daySummary.services.forEach((item) => {
      if (!item.appointments && !item.slots && !item.walkins) {
        return;
      }

      if (selectedServiceIds && selectedServiceIds.length) {
        if (!isServiceUsageOverviewItemMatchServiceIds(item, selectedServiceIds)) {
          return;
        }
      }

      const services = mapServiceIdsToServices(allServices, item.services);
      const serviceNames = services.map((service) => service.name);
      let displayOrder: number[] = [];
      if (services && services.length) {
        displayOrder = services.map((service) => service.displayOrder || -1).sort().reverse();
      }
      if (item.appointments || item.slots) {
        // Amount of slots is calculated as amount of appointments minus overflow plus remaining slots
        const slotsNumber = item.appointments + item.slots;
        overview.push({
          labels: serviceNames,
          value: `${item.appointments}/${slotsNumber}`,
          displayOrder,
        });
      }
      if (item.walkins) {
        overview.push({
          labels: serviceNames,
          value: `${item.walkins}`,
          isWalkin: true,
          displayOrder,
        });
      }
    });
  }

  overview.sort((a, b) => {
    if (a.displayOrder && b.displayOrder) {
      const sorted = sortByServicesCompareFn(a.displayOrder, b.displayOrder);
      if (sorted !== 0) {
        return sorted;
      }
      // now either they are equal or one array is longer than another
    }
    return a.labels[0] >= b.labels[0] ? 1 : -1;
  });

  if (daySummary.overflows) {
    overview.unshift({
      labels: ['calendarOverview.overflowItems'],
      value: daySummary.overflows,
      color: 'error',
    });
  }

  if (daySummary.employees) {
    overview.push({
      labels: ['calendarOverview.employees'],
      value: daySummary.employees,
    });
    res.employees = { value: daySummary.employees };
  }

  res.overview = overview;

  return res;
}

export function getCalendarGridStartEndDates(date: Dayjs, addMonths = 0) {
  const startOfPeriod = date.startOf('month').startOf('week');
  const endOfPeriod = date.endOf('month').add(addMonths, 'month').endOf('week');

  return {
    startOfPeriod,
    endOfPeriod,
  };
}
