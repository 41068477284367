import React from 'react';

import { ReactComponent as HA1BeratungsterminIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-1.beratungstermin.svg';
import { ReactComponent as HAErwNachkontrolleIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-erw.nachkontrolle.svg';
import { ReactComponent as HAFolgeterminIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-folgetermin.svg';
import { ReactComponent as HAGehorschutzIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-gehorschutz.svg';
import { ReactComponent as HAKurzhortestIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-kurzhortest.svg';
import { ReactComponent as HANachkontrolleIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-nachkontrolle.svg';
import { ReactComponent as HAServiceIcon } from '../../../assets/DesignAssets/BTL/Icons/ha-service.svg';
import { ReactComponent as BOAdvisory } from '../../../assets/DesignAssets/BTL/Icons/bo-advisory.svg';
import { ReactComponent as BODriverVisionTest } from '../../../assets/DesignAssets/BTL/Icons/bo-driver-vision-test.svg';
import { ReactComponent as BOAugen } from '../../../assets/DesignAssets/BTL/Icons/bo-augen.svg';
import { ReactComponent as BOPickup } from '../../../assets/DesignAssets/BTL/Icons/bo-pickup.svg';
import { ReactComponent as BOService } from '../../../assets/DesignAssets/BTL/Icons/bo-service.svg';
import { ReactComponent as BOServiceWorkshop } from '../../../assets/DesignAssets/BTL/Icons/bo-service-workshop.svg';
import { ReactComponent as BOSunglasses } from '../../../assets/DesignAssets/BTL/Icons/bo-sunglasses.svg';
import { ReactComponent as BOVisionTest } from '../../../assets/DesignAssets/BTL/Icons/bo-vision-test.svg';
import { ReactComponent as BOWorkshop } from '../../../assets/DesignAssets/BTL/Icons/bo-workshop.svg';
import { ReactComponent as EyeCheckUp } from '../../../assets/DesignAssets/BTL/Icons/eye-check-up.svg';
import { ReactComponent as HAAbgabeAbschluss } from '../../../assets/DesignAssets/BTL/Icons/ha-abgabe-abschluss.svg';
import { ReactComponent as KLAdjustment } from '../../../assets/DesignAssets/BTL/Icons/kl-adjustment.svg';
import { ReactComponent as KLFollowUpInspection } from '../../../assets/DesignAssets/BTL/Icons/kl-follow-up-inspection.svg';
import { ReactComponent as OtherOrderOptimization } from '../../../assets/DesignAssets/BTL/Icons/other-order-optimization.svg';
import { ReactComponent as OtherOther } from '../../../assets/DesignAssets/BTL/Icons/other-other.svg';
import { ReactComponent as Screwdriver } from '../../../assets/DesignAssets/BTL/Icons/screwdriver.svg';

export const serviceIconMap: Record<string, React.ComponentType> = {
  BOAdvisory,
  BODriverVisionTest,
  BOEyeCheckUp: BOAugen,
  BOPickup,
  BOService,
  BOServiceWorkshop,
  BOSunglasses,
  BOVisionTest,
  BOWorkshop,
  EyeCheckUp,
  HAAdultPostControl: HAErwNachkontrolleIcon,
  HAEarProtection: HAGehorschutzIcon,
  HAFirstConsultation: HA1BeratungsterminIcon,
  HAFollowUpAppointment: HAFolgeterminIcon,
  HAPostControl: HANachkontrolleIcon,
  HAService: HAServiceIcon,
  HAShortHearingTest: HAKurzhortestIcon,
  HASubmissionCompletion: HAAbgabeAbschluss,
  KLAdjustment,
  KLFollowUpInspection,
  OtherOrderOptimization,
  OtherOther,
  Screwdriver,
};
