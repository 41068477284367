import { CalendarEventTypeEnum } from 'types';

export interface Customer {
  name: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  gender?: string;
  locale: string;
  externalCustomerId?: string;
}

export type AppointmentEvent = {
  suggestedEmployee?: string;

  services?: string[];

  resources?: string[];

  customer?: Customer;
};

export type BreakEvent = {};

export type BlockerEvent = {};

export type TaskEvent = {
  suggestedEmployee?: string;

  services?: string[];

  resources?: string[];

  customer?: Customer;
};

export type WalkinEvent = {
  services?: string[];

  resources?: string[];
};

export enum CalendarEventStatusENUM {
  DELETED = 0,
  ACTIVE = 1,
  DRAFT = -1,
  NO_SHOW = 3,
  COMPLETED = 4,
  CANCELED = 5,
}

export type Appointment = {
  id: string;
  duration: number; // 15
  status: CalendarEventStatusENUM; // 1
  date: string; // '2023-10-19
  time: number; // '600 minutes from day start
  title?: string;
  type: CalendarEventTypeEnum;
  metadata?: string;
  storeId?: string;
  enterpriseId?: string;
  pinnedEmployee?: string;
  note?: string;
  createdBy: string;
  updatedBy?: string;
  eventTypeData: AppointmentEvent | BlockerEvent | BreakEvent | TaskEvent | WalkinEvent; // Any data specific to any type of event
  createdAt: number;
  updatedAt: number;
};

export type ExternalAppointment = Omit<Appointment, 'date' | 'time'> & {
  dateTime: number;
};

export type AppointmentUpdate =
  & Omit<Appointment,
  'id' | 'storeId' | 'status' | 'createdAt' | 'createdBy' | 'updatedAt' | 'services' | 'customer'>
  & { id?: string, services?: string[], customer?: Customer };

export type AppointmentCreate = {
  services?: string[];
  resources?: string[];
  duration?: number;
  date: string;
  time: number;
  customer?: Customer;
  externalCustomerId: string;
  title?: string;
  storeId?: string;
  timeZone: string;
  externalStoreId?: string;
  metadata?: string;
  externalServiceIds?: string[];
  externalResourceIds?: string[];
};

export type SearchCalendarEventDocument = {
  objectID: string; //   algoliaId:"event-6557a667909a000318e7f478",  // NOT SEARCH
  type: string; //   type:"calendarEvent",   // filter   NOT SEARCH
  enterpriseId: string; //   enterpriseId: 'test', // filter   NOT SEARCH
  storeId: string; //   storeId: "654357d6ebd59482a93b0fd4", // filter NOT SEARCH
  eventId: string; //   eventId: "6557a667909a000318e7f478",  // NOT SEARCH
  dateTime: number; //   dateTime: 1700323200000,
  duration: number; //   duration: 30,
  suggestedEmployeeId?: string;
  suggestedEmployeeName?: string;
  suggestedEmployeeFirstName?: string;
  suggestedEmployeeLastName?: string;
  actionReason?: string;
  title: string; //   title: 'Test Dirk',
  note: string; //   note: '',
  calendarEventType: CalendarEventTypeEnum; //   caleventType: 'appointment', // NOT SEARCH
  serviceNames?: string[]; //   services: [ 'vision test' ],
  serviceIds?: string[];
  customerName?: string; //   customerName: 'Test',
  customerLastName?: string; //   customerlastName: 'Dirk',
  customerPhoneNumber?: string; //   customerphoneNumber: '32424',
  customerEmail?: string; //   customeremail: 'ali.arseven@fielmann.com',
  customerGender?: string; //   customergender: 'male',
  customerLocale?: string; //   customerlocale: 'en' // NOT SEARCHABLE
};
