import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { CustomEventProps, ReservationComponentProps } from '../types';
import { ServiceIcon } from '../../commons';

import styles from './DayEvent.module.scss';

export function Reservation30(
  {
    title,
    timeRange,
    event,
    borderColor,
    backgroundColor,
    serviceBackgroundColor,
  }: ReservationComponentProps,
) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventReservation)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div
              key={service.id}
              className={clsx([styles.dayEventServicesItem])}
              style={{ backgroundColor: serviceBackgroundColor }}
            >
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
        </Stack>

        <Stack direction="column" overflow="hidden" spacing={1} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <span
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextUppercase,
              )}
            >
              {title}
            </span>
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>
            {timeRange}
          </span>
        </Stack>
      </Stack>
    </div>
  );
}

export function Reservation15(
  {
    title,
    timeRange,
    event,
    borderColor,
    backgroundColor,
    serviceBackgroundColor,
  }: ReservationComponentProps,
) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootDense, styles.dayEventReservation)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div
              key={service.id}
              className={clsx([styles.dayEventServicesItem, styles.dayEventServicesItemDense])}
              style={{ backgroundColor: serviceBackgroundColor }}
            >
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
        </Stack>

        <Stack direction="row" spacing={1.5} alignItems="center" className={clsx([styles.dayEventBody, styles.dayEventBodyDense])}>
          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>
            {timeRange}
          </span>

          <span
            className={clsx(
              styles.dayEventBodyText,
              styles.dayEventBodyTextBold,
              styles.dayEventBodyTextNoWrap,
              styles.dayEventBodyTextUppercase,
            )}
          >
            {title}
          </span>
        </Stack>
      </Stack>
    </div>
  );
}

export function DayEventReservation({ event }: CustomEventProps) {
  const intl = useIntl();

  const DayEventComponent = useMemo(() => {
    const duration = event.duration || 10;

    if (duration >= 30) {
      return Reservation30;
    }

    return Reservation15;
  }, [event.duration]);

  const timeRange = useMemo(() => `${event.startFormatted} - ${event.endFormatted}`, [event]);

  const colorProps = useMemo(() => ({
    serviceBackgroundColor: '#F6F6F8',
  }), [event]);

  return (
    <DayEventComponent
      title={intl.formatMessage({ id: 'calendar.reserveTitle' })}
      timeRange={timeRange}
      event={event}
      {...colorProps}
    />
  );
}
