import { createAsyncThunk } from '@reduxjs/toolkit';
import CLIENT from '../../utils/CLIENT';
import { TimeHelper } from '../../utils';
import dayjs from 'dayjs';

interface Payload {
  services?: string[];
  date: string;
  timestamp: number;
}

interface FulfillValue {
  data: any;
  timestamp: number;
}

interface RejectValue {
  error: string;
  timestamp: number;
}

export const getCalendarSlots = createAsyncThunk<FulfillValue, Payload, { rejectValue: RejectValue }>(
  'calendarSlots/get',
  async (payload, thunkAPI) => {
    try {
      const timeZone = 'Europe/Berlin'; // state?.store?.data?.timeZone;
      let query = `timestamp=${payload.timestamp}&days=${payload.date}`;
      if (payload.services) {
        query += `&serviceIds=${payload.services.join(',')}`;
      }

      const { data: responseData } = await CLIENT({
        url: `/slots?${query}`,
        method: 'GET',
      });

      if (responseData.data.slots) {
        const slots = {};
        const slotDays = Object.keys(responseData.data.slots);
        slotDays.forEach((slotday) => {
          if (responseData.data.slots[slotday]) {
            slots[slotday] = {};
            const employeeIds = Object.keys(responseData.data.slots[slotday]);
            employeeIds.forEach((employee) => {
              slots[slotday][employee] = responseData.data.slots[slotday][employee].map((slot) => {
                const dateTime = TimeHelper.dateAndTimeToDateAndTimeTz(slotday, slot.time, timeZone);

                return {
                  ...slot,
                  time: dateTime.time,
                };
              });
            });
          }
        });
        return { data: { ...responseData.data, slots }, timestamp: responseData.timestamp };
      }

      return responseData;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }

      return thunkAPI.rejectWithValue({
        error: error.message,
        timestamp: payload.timestamp,
      });
    }
  },
);
