import React from 'react';
import { serviceIconMap } from './serviceIconMap';

type Props = {
  icon?: string;
};

const getServiceIcon = (icon?: string): React.ComponentType | null => {
  if (!icon || !serviceIconMap[icon]) {
    return null;
  }

  return serviceIconMap[icon];
};

export function ServiceIcon({ icon }: Props) {
  const IconComponent = getServiceIcon(icon);

  if (!IconComponent) {
    return null;
  }

  return (<IconComponent />);
}

ServiceIcon.defaultProps = {
  icon: undefined,
};
