import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import CLIENT from 'utils/CLIENT';
import {
  Appointment,
  AppointmentEvent,
  CalendarEventStatusENUM,
  CalendarEventTypeEnum,
  Employee,
  ExternalAppointment,
  ICreateCalendarEventQueryParams,
  IUpdateCalendarEventQueryParams,
  SearchCalendarEventDocument,
} from 'types';
import { APPOINTMENTS_GET_ERROR } from '../types/appointments';
import { TimeHelper } from '../../utils';

export const convertExternalAppointment = (obj: ExternalAppointment, timeZone: string): Appointment => {
  const dateTime = TimeHelper.timestampToDateAndTimeTz(obj.dateTime, timeZone);
  const res: Appointment & { dateTime?: number } = {
    ...obj,
    date: dateTime.date,
    time: dateTime.time,
  };
  delete res.dateTime;
  return res;
};

export const convertSearchResult = (
  obj: SearchCalendarEventDocument,
  timeZone,
): Appointment & {
  employee?: Pick<Employee, 'id' | 'name' | 'firstName' | 'lastName'>
} => {
  const dateTime = TimeHelper.toDayjs(obj.dateTime, timeZone);
  const res: any = {
    id: obj.eventId,
    date: TimeHelper.toStandardFormat(dateTime),
    time: TimeHelper.convertToMinutesFromDayStart(dateTime),
    type: obj.calendarEventType,
    note: obj.note,
    title: obj.title,
    duration: obj.duration,
    storeId: obj.storeId,
    enterpriseId: obj.enterpriseId,
    status: CalendarEventStatusENUM.ACTIVE,
  };

  if (![CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK, CalendarEventTypeEnum.WALKIN].includes(obj.calendarEventType)) {
    return res;
  }
  const eventTypeData: AppointmentEvent = {
    services: obj.serviceIds ? obj.serviceIds : [],
  };
  if ([CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(obj.calendarEventType)) {
    eventTypeData.customer = {
      name: obj.customerName || '',
      lastName: obj.customerLastName || '',
      phoneNumber: obj.customerPhoneNumber || undefined,
      email: obj.customerEmail || '',
      gender: obj.customerGender || undefined,
      locale: obj.customerLocale || '',
    };
    if (obj.suggestedEmployeeId) {
      eventTypeData.suggestedEmployee = obj.suggestedEmployeeId;
    }
  }
  res.eventTypeData = eventTypeData;

  if (obj.suggestedEmployeeId) {
    res.employee = {
      id: obj.suggestedEmployeeId,
      firstName: obj.suggestedEmployeeFirstName,
      lastName: obj.suggestedEmployeeLastName,
      name: obj.suggestedEmployeeName,
    };
  }

  return res;
};

export const convertToExternalAppointment = (
  obj: Partial<Omit<Appointment, 'type'>> & { date: string; time: number },
  timeZone: string,
): Partial<ExternalAppointment> => {
  const dateTime = TimeHelper.toDayjs(obj.date, timeZone);
  const merged = dateTime.add(obj.time, 'minutes');
  const res: Partial<ExternalAppointment> & { time?: number; date?: string } = {
    ...obj,
    dateTime: merged.valueOf(),
  };
  delete res.date;
  delete res.time;
  return res;
};

export const fetchCalendarEvents = createAsyncThunk(
  'calendar-events/get',
  async (payload: { from: Dayjs; to: Dayjs; timeZone: string }) => {
    const from = payload.from.format('YYYY-MM-DD');
    const to = payload.to.format('YYYY-MM-DD');
    const response = await CLIENT({
      url: `/calendar-events?page=1&itemsPerPage=250&from=${from}&to=${to}`, // todo remove pagination
      method: 'GET',
    });

    if (response.data.error) {
      throw new Error(APPOINTMENTS_GET_ERROR);
    }

    return response.data.map((calendarEvent) => convertExternalAppointment(calendarEvent, payload.timeZone));
  },
);

export const searchCalendarEvents = createAsyncThunk(
  'calendar-events/search',
  async (payload: {
    term: string;
    from: Dayjs;
    to: Dayjs;
    page: number;
    itemsPerPage: number;
    timeZone: string;
    storeId: string;
    enterpriseId: string;
  }) => {
    const query = [
      `term=${payload.term}`,
      `from=${payload.from.format('YYYY-MM-DD')}`,
      `to=${payload.to.format('YYYY-MM-DD')}`,
      `page=${payload.page}`,
      `itemsPerPage=${payload.itemsPerPage}`,
      `storeId=${payload.storeId}`,
      `enterpriseId=${payload.enterpriseId}`,
    ];
    const response = await CLIENT({
      url: `/calendar-events/search?${query.join('&')}`,
      method: 'GET',
    });

    if (response.data.error) {
      throw new Error(APPOINTMENTS_GET_ERROR);
    }

    return response.data.map((calendarEvent) => convertSearchResult(calendarEvent, payload.timeZone));
  },
);

export const getCalendarEvent = createAsyncThunk('calendar-events/getOne', async (payload: { appointmentId: string }, thunkAPI) => {
  const state = thunkAPI.getState() as any;
  const timeZone = state.store.data?.timezone || 'Europe/Berlin';

  const response = await CLIENT({
    url: `/calendar-events/${payload.appointmentId}`,
    method: 'GET',
  });

  return convertExternalAppointment(response.data, timeZone);
});

export const updateCalendarEvents = createAsyncThunk(
  'calendar-events/update',
  async (payload: { appointmentId: string; params: IUpdateCalendarEventQueryParams }, thunkAPI) => {
    const state = thunkAPI.getState() as any;
    const timeZone = state.store.data?.timezone || 'Europe/Berlin';

    const params = convertToExternalAppointment(payload.params, timeZone);

    const response = await CLIENT({
      url: `/calendar-events/${payload.appointmentId}`,
      body: {
        ...params,
        id: payload.appointmentId,
      },
      method: 'PATCH',
    });

    return convertExternalAppointment(response.data, timeZone);
  },
);

export const createCalendarEvents = createAsyncThunk(
  'calendar-events/create',
  async (params: ICreateCalendarEventQueryParams, thunkAPI) => {
    const state = thunkAPI.getState() as any;
    const timeZone = state.store.data?.timezone || 'Europe/Berlin';

    const response = await CLIENT({
      url: '/calendar-events',
      body: convertToExternalAppointment(params, timeZone),
      method: 'POST',
    });

    return convertExternalAppointment(response.data, timeZone);
  },
);

export const deleteCalendarEvents = createAsyncThunk('calendar-events/delete', async (payload: { id: string; reason: string }) => {
  const { id, reason } = payload;
  const response = await CLIENT({
    url: `/calendar-events/${id}`,
    body: { id, reason },
    method: 'DELETE',
  });

  return response.data;
});

export const syncBreaksToDiwa = createAsyncThunk('syncBreaksToDiwa', async (payload: { date: Dayjs; storeId: string }) => {
  const date = payload.date.format('YYYY-MM-DD');
  const response = await CLIENT({
    url: `/calendar-events/syncBreaksToDiwa?storeId=${payload.storeId}&date=${date}`,
    method: 'GET',
  });

  if (response.data.error) {
    throw new Error('Breaks sync error');
  }

  return response.data;
});
