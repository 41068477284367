import React, { useMemo } from 'react';
import { Box, Stack, ToggleButtonGroup } from '@mui/material';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { ToggleButton } from '@linetweet/linetweet-ui';

import { Service } from '../../../types';
import { ServiceIcon } from '../ServiceIcon';

import styles from './ServiceTabSelect.module.scss';

export interface ServiceTabsSelectOption {
  label: string;
  value: string;
  name: string;
  tabId: string;
  extra: Service;
}

export interface ServiceTabSelectProps {
  options: ServiceTabsSelectOption[];
  value: ServiceTabsSelectOption[];
  activeTabId: string;
  tabs: ICategory[];
  onTabChange: (tabId: string) => void;
  onServiceChange: (services: ServiceTabsSelectOption[]) => void;
  ignoreResetOnTabChange?: boolean;
  ignoreServiceDuration?: boolean;
  withLightColors?: boolean;
  isDisabled?: boolean;
  exclusive?: boolean;
  allowToggle?: boolean;
}

export type ICategory<TValue = {}> = TValue & {
  id: string;
  name: string;
};

export function ServiceTabSelect({
  options,
  tabs,
  activeTabId,
  onTabChange,
  value,
  onServiceChange,
  ignoreResetOnTabChange,
  ignoreServiceDuration,
  withLightColors,
  isDisabled,
  exclusive,
  allowToggle,
}: ServiceTabSelectProps) {
  const intl = useIntl();

  const optionsByTabMap = useMemo(
    () =>
      options.reduce((acc, option) => {
        if (!acc[option.tabId]) {
          acc[option.tabId] = [];
        }

        acc[option.tabId].push(option);
        return acc;
      }, {}),
    [options],
  );

  const valuesByTabMap = useMemo(
    () =>
      value.reduce((acc, valueItem) => {
        if (!acc[valueItem.tabId]) {
          acc[valueItem.tabId] = [];
        }

        acc[valueItem.tabId].push(valueItem);

        return acc;
      }, {}),
    [value],
  );

  return (
    <Stack direction="column">
      <ToggleButtonGroup
        exclusive
        className={clsx(styles.tabsContainer, {
          [styles.lightColors]: withLightColors,
          [styles.oneTab]: tabs.length === 1,
          [styles.twoTabs]: tabs.length === 2,
          [styles.threeTabs]: tabs.length === 3,
        })}
        value={activeTabId}
        onChange={(_event, updatedTabId) => {
          if (!updatedTabId) return;

          if (!ignoreResetOnTabChange) {
            onServiceChange([]);
          }
          onTabChange(updatedTabId);
        }}
      >
        {tabs ? (
          tabs.map((tab) => (
            <ToggleButton key={tab.id} color="primary" value={tab.id} disabled={!!isDisabled}>
              {tab.name}
              {!exclusive && valuesByTabMap[tab.id]?.length && <span className={styles.tabBadge} />}
            </ToggleButton>
          ))
        ) : (
          <></>
        )}
      </ToggleButtonGroup>

      <Box className={clsx(styles.servicesList, { [styles.lightColors]: withLightColors })}>
        {optionsByTabMap[activeTabId] ? (
          optionsByTabMap[activeTabId].map((option) => {
            const selected = !!value.find((valueItem) => valueItem.value === option.value);

            return (
              <ToggleButton
                key={option.value}
                variant="rounded"
                color="secondary"
                fullWidth
                value={option}
                startIcon={<ServiceIcon icon={option.extra.icon} />}
                className={clsx(styles.serviceButtonContainer, { [styles.selectedService]: selected })}
                selected={selected}
                disabled={!!isDisabled}
                onChange={(event, updatedValue: ServiceTabsSelectOption) => {
                  let nextValue: ServiceTabsSelectOption[] = [];

                  if (selected) {
                    if (!allowToggle) {
                      return;
                    }

                    value.forEach((valueItem) => {
                      if (valueItem.value === updatedValue.value || updatedValue.extra.relatedServices?.includes(valueItem.value)) {
                        return;
                      }

                      if (!nextValue.find((nextValueItem) => nextValueItem.value === valueItem.value)) {
                        nextValue.push(valueItem);
                      }
                    });
                  } else {
                    if (!exclusive) {
                      nextValue = [...value];
                    }

                    const { relatedServices } = updatedValue.extra;

                    options.forEach((valueItem) => {
                      if (updatedValue.value === valueItem.value || relatedServices?.includes(valueItem.value)) {
                        nextValue.push(valueItem);
                      }
                    });
                  }

                  onServiceChange(nextValue);
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" className={styles.serviceButtonText}>
                  <Box className={styles.serviceButtonTextLabel}>{option.label}</Box>
                  {!ignoreServiceDuration && (
                    <Box className={styles.serviceButtonTextDuration}>
                      {intl.formatMessage({ id: 'common.minutesShort' }, { minutes: option.extra.duration })}
                    </Box>
                  )}
                </Stack>
              </ToggleButton>
            );
          })
        ) : (
          <> </>
        )}
      </Box>
    </Stack>
  );
}

ServiceTabSelect.defaultProps = {
  ignoreResetOnTabChange: false,
  ignoreServiceDuration: false,
  withLightColors: false,
  isDisabled: undefined,
  exclusive: undefined,
  allowToggle: undefined,
};
